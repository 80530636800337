<template>
  <div :class="{ jw74: isJw74 }">
    <component :is="layout" />
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/Empty'
import MainLayout from '@/layouts/Main'
import ErrorLayout from '@/layouts/Error'

export default {
  name: 'App',

  components: {
    EmptyLayout,
    MainLayout,
    ErrorLayout,
  },

  computed: {
    layout() {
      if (this.errors) {
        return 'error' + '-layout'
      }

      return (this.$route.meta.layout || 'main') + '-layout'
    },

    errors() {
      return this.$store.getters['app/errors']
    },

    isJw74() {
      return (
        localStorage.getItem('merchantKey') ===
        '776151e60de0b99f011a13fe4cb84d17'
      )
    },

    cart() {
      return this.$store.state.checkout.cart
    },
  },

  watch: {
    cart(cart, oldCart) {
      if (
        cart?.data?.item?.length &&
        JSON.stringify(cart.data.item) != JSON.stringify(oldCart?.data?.item)
      ) {
        this.$emitter.emit('cartItemsChanges', {
          products: cart.data.item.map((item) => ({
            productId: item.item_id,
            count: Number(item.qty),
            price: Number(item.discounted_price || item.normal_price) || 0,
            name: item.item_name,
          })),
        })
      }
    },
  },
}
</script>
