const prefix = 'cdp'

export async function cdpInit({ scriptUrl, apiUrl, sourceId }) {
  await import('./compiled.js')

  window.options = {
    tracker: {
      url: {
        script: scriptUrl,
        api: apiUrl,
      },
      source: {
        id: sourceId,
      },
      context: {
        browser: false,
        page: true,
        session: false,
        storage: false,
        screen: false,
        performance: false,
      },
    },
  }

  window.tracker.track('page_view', {})

  return new Promise((resolve) => {
    window.onTracardiReady.bind((cdp) => {
      const track = cdp.helpers.track

      cdp.helpers.track = async (...args) => {
        const response = await track(...args)
        handleResponse(response)
      }

      resolve(cdp)

      setTimeout(() => {
        if (Array.isArray(cdp.context?.ux)) {
          cdp.context.ux.forEach((item, index) => {
            const [attr, value] = Object.entries(item.props)[0]
            const el = document.querySelector(`[${attr}="${value}"]`)
            if (el) {
              el.id = `${prefix}-${index}`
            }
          })
        }
      })
    })
  })
}

export function handleResponse(response) {
  if (Array.isArray(response?.data?.ux) && response.data.ux.length > 0) {
    const els = document.querySelectorAll(`[id^="${prefix}-"]`)
    els.forEach((el) => el.remove())

    response.data.ux.forEach((item, index) => {
      const el = document.createElement(item.tag)
      for (const key in item.props) {
        el.setAttribute(key, item.props[key])
      }
      el.id = `${prefix}-${index}`
      if (item.content) {
        el.text = item.content
      }
      document.body.appendChild(el)
    })
  }
}
