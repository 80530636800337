import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/reactive'
import { roundupPrice } from './utils'
import vuetify from './plugins/vuetify'
import api from './plugins/api'
import noPhoto from '@/assets/images/no-photo.svg'
import VueLazyLoad from 'vue-lazyload'
import '@/assets/scss/jw74.scss'
import recaptcha from '@/plugins/recaptcha'
import customTracker from '@/plugins/customTracker'
import device from 'vue-device-detector'
import { cdpInit } from './cdp/index'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

Vue.use(device)

Vue.use(VueLazyLoad, {
  preLoad: 1,
  attempt: 1,
  loading: noPhoto,
  error: noPhoto,
  listenEvents: ['scroll'],
})

import VueMask from 'v-mask'
Vue.use(VueMask)

import truncateFilter from '@/filters/truncate'
Vue.filter('truncate', truncateFilter)

import '@/assets/scss/main.scss'
import '@/assets/fonts/stylesheet.css'

Vue.prototype.$api = api
Vue.prototype.$price = { roundupPrice }
// Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  const query = to.query || {}

  if (query.utm_source) {
    sessionStorage.setItem('utm_source', query.utm_source)
  }

  if (query.utm_medium) {
    sessionStorage.setItem('utm_medium', query.utm_medium)
  }

  if (query.utm_campaign) {
    sessionStorage.setItem('utm_campaign', query.utm_campaign)
  }

  const isAuthorized = store.getters['user/isAuthorized']

  if (to.meta.auth && !isAuthorized) {
    next({ name: 'Welcome' })
  } else if (to.meta.guest && isAuthorized) {
    next({ name: 'Welcome' })
  } else next()
})

if (
  window.location.hostname === 'qr.vsem-edu-oblako.ru' &&
  !Vue.$device.mobile
) {
  document.querySelector('.preloader').innerHTML =
    '<h3>QR-меню доступно для просмотра только с мобильного устройства</h3>'
} else if (window.browser) {
  onDeviceReady()
} else {
  document.addEventListener('deviceready', onDeviceReady, false)
}

async function onDeviceReady() {
  pullMerchantKey()

  await store.dispatch('appInit')

  const isDev = window.location.protocol === 'http:'

  const cdpConfig = store.state.app.settings.metrica_data?.cdp || {}

  const apiUrl = cdpConfig.api_url
  const scriptUrl = cdpConfig.script_url
  const sourceId = cdpConfig.source_id_mobile

  if (apiUrl && scriptUrl && sourceId && !isDev) {
    cdpInit({ scriptUrl, apiUrl, sourceId }).then((cdp) => {
      Vue.prototype.$cdp = cdp
      const track = cdp.helpers.track
      const emitter = Vue.prototype.$emitter

      router.afterEach(() => {
        track('page_view', {})
      })

      if (window.notificationOpenedPayload) {
        track('push_click', window.notificationOpenedPayload)
      }

      emitter.on('login', (phone) => {
        track('sign-in', {
          phone: {
            main: phone?.replace?.(/\D+/g, ''),
          },
        })
      })

      emitter.on('updateUserProfile', (payload) => {
        const dataPii = {}
        if (payload.name) {
          dataPii.firstname = payload.name
        }
        if (payload.birthday) {
          const split = payload.birthday.split('.')
          const [day, month, year] = split
          const formatted = [year, month, day].join('-')
          dataPii.birthday = dayjs(formatted).format('YYYY-MM-DDTHH:mm:ss')
        }
        const dataContact = {}
        if (payload.phone) {
          dataContact.phone = {
            main: payload.phone?.replace?.(/\D+/g, ''),
          }
        }
  
        track('profile-update', {
          pii: dataPii,
          contact: dataContact,
        })
      })

      emitter.on('cartEmpty', () => {
        track('cart-delete', {})
      })

      emitter.on('cartItemsChanges', (items) => {
        track('cart-changes', items)
      })

      emitter.on('order-submit', (order) => {
        track('checkout-started', {
          id: order.id,
          income: {
            value: order.total,
            currency: 'RUB',
          },
          cost: {
            value: order.total,
            currency: 'RUB',
          },
          value: order.total,
          products: order.items?.map?.((item) => ({
            id: item.id,
            name: item.name,
            price: Number(item.price) || 0,
            quantity: Number(item.quantity),
          })),
          point: order.point,
          custom: order.custom,
          channel: order.channel,
          order_type: order.order_type,
        })
      })

      emitter.on('identification', ({ phone, name }) => {
        track('identification', {
          phone: {
            main: phone?.replace?.(/\D+/g, ''),
          },
          firstname: name,
        })
      })

      emitter.on('productDetails', (product) => {
        track('product-details-page-view', {
          id: product.id,
          name: product.name,
          price: product.price,
          size: product.variant,
        })
      })
    })
  }

  Vue.use(recaptcha)
  Vue.use(customTracker)

  if (store.getters['aggregator/isAggregator']) {
    store.commit('aggregator/setKey', localStorage.getItem('merchantKey'))
    sessionStorage.setItem('aggregatorKey', localStorage.getItem('merchantKey'))
  } else if (store.getters['aggregator/isAggregatorChild']) {
    store.commit('aggregator/setKey', sessionStorage.getItem('aggregatorKey'))
  }

  const cities = store.getters['app/cities']
  if (cities && Object.keys(cities).length) {
    store.commit('menu/show')
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app')

  const params = getQueryParams()
  if (params && params.get('page') === 'orderSuccess' && params.get('id')) {
    router.push({
      name: 'PaymentSuccess',
      query: {
        id: params.get('id'),
      },
    })
    store.commit('menu/hide')
  }
}

function pullMerchantKey() {
  const params = getQueryParams()
  const merchantKey =
    params.get('merchantKey') ||
    localStorage.getItem('merchantKey') ||
    window.merchantKey
  localStorage.setItem('merchantKey', merchantKey)
  localStorage.setItem('platform', params.get('platform') || 'express')
  return merchantKey
}

function getQueryParams() {
  return new URLSearchParams(window.location.search)
}

window.addEventListener('cordovaEvent', async (event) => {
  const { name: eventName, data: eventData } = event.detail

  if (eventName === 'notificationOpened') {
    const notification = eventData.notification || {}
    const additionalData = notification.additionalData || {}

    if (additionalData.type) {
      const payload = {
        title: notification.title,
        message: notification.body,
        type: additionalData.type,
      }

      if (Vue.prototype.$cdp) {
        Vue.prototype.$cdp.helpers.track('push_click', payload)
      } else {
        window.notificationOpenedPayload = payload
      }
    }
  }

  if (eventName === 'fcmToken' && eventData.value) {
    store.commit('app/setFcmToken', eventData.value)
    // await store.dispatch("app/loadSettings");
    await store.dispatch('checkout/loadCart')
  }

  if (eventName === 'playerId' && eventData.value) {
    const playerId = eventData.value
    store.commit('app/setPlayerId', playerId)

    if (Vue.prototype.$cdp && window.playerId !== playerId) {
      Vue.prototype.$cdp.helpers.track('profile-traits-update', {
        push_id: playerId,
      })
      window.playerId = playerId
    }
  }

  if (eventName === 'route' && eventData.path) {
    router.push(eventData.path)
  }
})

document.body.onclick = function (event) {
  function handleLink(href) {
    if (window.webkit?.messageHandlers?.cordova_iab?.postMessage) {
      event.preventDefault()
      try {
        window.webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            type: 'open',
            data: {
              url: href,
              target: '_system',
            },
          })
        )
      } catch (error) {
        alert(error)
      }
    }
  }
  const target = event.target.closest('a')
  if (
    target &&
    target.classList.contains('external-link') &&
    document.body.contains(target)
  ) {
    return handleLink(target.getAttribute('href'))
  }
}
