import qs from 'qs'

const state = () => ({
  cart: {},
  recommendations: [],
  dateList: [],
  timeList: [],
  paymentList: [],
  pickupPoints: [],
  deliveryTypes: [],
  cartCount: 0,
  fields: {
    addressLine: '',
    addressCoords: '',
    addressCity: '',

    name: '',
    phone: '',

    street: '',
    house: '',
    apart: '',

    et: '',
    pod: '',
    domofon: '',
    count_person: '',
    housing: '',

    asap: true,
    deliveryDate: '',
    deliveryTime: '',

    paymentType: '',
    comment: '',

    pickupPoint: '',

    table: '',

    changeOrder: '',

    orderEnsure: false,

    zone: '',
  },
  paymentToken: null,

  orderType: '',
  dineinPoint: '',

  geoError: false,
})

const getters = {
  deliveryType: (s) => {
    return window.QR_MENU ? 'dinein' : s.cart?.transaction_type || s.orderType
  },

  orderTypes: (s) => s.deliveryTypes,

  pickupPoints: (s) => s.pickupPoints,

  dineinPoints: (s, getters) =>
    getters.pickupPoints.filter((item) => item.is_dine_in),

  selectedOrderType: (s, getters) => {
    return s.orderType || getters.deliveryType
  },

  useDeliveryZones: (state, getters, rootState, rootGetters) =>
    rootGetters['app/useGeoAddressDelivery'],

  addressName: (state, getters) => {
    if (getters.selectedOrderType === 'pickup') {
      return state.pickupPoints.find(
        (item) => item.value === state.fields.pickupPoint
      )?.text
    }

    if (getters.selectedOrderType === 'dinein') {
      return getters.dineinPoints.find(
        (item) => item.value === state.dineinPoint
      )?.text
    }

    if (getters.selectedOrderType === 'delivery') {
      const { street, house } = state.fields
      if (street && house) {
        return [street, house].filter(Boolean).join(' ')
      }
    }
  },

  promoDetails(s) {
    const details = s.cart?.cart_details?.voucher_details
    return details && JSON.parse(details)
  },

  cartIsEmpty({ cart }) {
    const count = cart?.cart_details?.cart_count
    return !count
  },

  cartItems({ cart }) {
    return cart?.data?.item || []
  },

  recommendations({ recommendations }) {
    return recommendations || []
  },

  cartTotal({ cart }) {
    return cart?.data?.total
  },

  itemsPrice(state, { cartSubtotalPrice, cartDiscount, pointsApply }) {
    return cartSubtotalPrice + cartDiscount + pointsApply
  },

  cartDiscount({ cart }) {
    return Number(cart?.basket_discount) || 0
  },

  cartSubtotalPrice({ cart }) {
    return Number(cart?.basket_total) || 0
  },

  cartTotalPrice({ cart }) {
    return Number(cart?.data?.total?.total) || 0
  },

  deliveryPrice(state, { cartTotal }) {
    return Number(cartTotal?.delivery_charges) || 0
  },

  pointsAvailable({ cart }) {
    return Number(cart?.available_points) || 0
  },

  pointsApply({ cart }) {
    return Number(cart?.cart_details?.points_apply) || 0
  },

  geoError: (state) => state.geoError,
}

const mutations = {
  setPaymentToken(state, value) {
    state.paymentToken = value
  },

  setCart(state, cart) {
    state.cart = cart
  },

  setCartCount(state, value) {
    state.cartCount = value
  },

  setRecommendations(state, recommendations) {
    state.recommendations = recommendations
  },

  setDateList(state, list) {
    state.dateList = list
  },

  setTimeList(state, list) {
    state.timeList = list
  },

  setPaymentList(state, list) {
    state.paymentList = list
  },

  setDeliveryType(state, value) {
    state.selectedDeliveryType = value
  },

  setPickupPoints(state, value) {
    state.pickupPoints = value
  },

  setPickupPoint(state, value) {
    state.fields.pickupPoint = value
  },

  setDineInPoint(state, value) {
    state.dineinPoint = value
  },

  setField(state, { name, value }) {
    state.fields[name] = value
  },

  setOrderTypes(state, value) {
    if (!value) return
    state.deliveryTypes = Object.entries(value).map(([code, name]) => ({
      text: name,
      value: code,
    }))
  },

  setOrderType(state, value) {
    state.orderType = value
  },

  setGeoError(state, value) {
    state.geoError = value
  },
}

const actions = {
  async clearCart({ dispatch }) {
    await this.$api.get('clearCart')
    this.$emitter.emit('cartEmpty')
    await dispatch('loadCart')
  },

  async loadCart(
    { getters, commit, dispatch, rootGetters, state },
    deliveryType
  ) {
    const transaction_type = deliveryType || getters.deliveryType

    commit('setOrderType', transaction_type)

    const { history } = this.$router

    const params = {}

    if (history.current?.name === 'Cart') {
      if (rootGetters['app/isIikoEnabled']) {
        params.iiko_cart_calc = 1
      }
      const response = await this.$api.get('recommendCart', {
        params: { transaction_type, ...params },
      })
      commit(
        'setRecommendations',
        Object.assign(
          { ...(response.details || {}) },
          { msg: response.msg },
          {}
        )
      )
    } else if (history.current?.name === 'Checkout') {
      if (rootGetters['app/isIikoEnabled']) {
        params.iiko_cart_calc = 1
      }
    }

    if (!state.fields.asap) {
      if (state.fields.deliveryDate) {
        params.delivery_date = state.fields.deliveryDate
      }
      if (state.fields.deliveryTime) {
        params.delivery_time = state.fields.deliveryTime
      }
    }

    const response = await this.$api.get('loadCart', {
      params: { transaction_type, ...params },
    })

    commit(
      'setCart',
      Object.assign({ ...(response.details || {}) }, { msg: response.msg }, {})
    )

    const items = response?.details?.data?.item

    const count = (items && Object.keys(items).length) || 0

    commit('setCartCount', count)

    if (history.current?.name === 'Cart') {
      const { deliveryDate } = state.fields
      if (deliveryDate) {
        await dispatch('loadTimeList', deliveryDate)
        if (state.timeList) {
          commit('setField', {
            name: 'deliveryTime',
            value: state.timeList && state.timeList[0],
          })
        }
      }
    }

    await dispatch('gift/loadGiftList', null, { root: true })
  },

  async addItem({ dispatch }, params) {
    const response = await this.$api.post('addToCart', qs.stringify(params))
    // const count = response?.details?.cart_count;
    // commit("setCartCount", count);
    await dispatch('loadCart')
    return response
  },

  async removeItem({ dispatch, getters }, row) {
    await this.$api.get('removeCartItem', { params: { row } })
    await dispatch('loadCart')
    if (getters.cartIsEmpty) {
      this.$emitter.emit('cartEmpty')
    }
  },

  async loadDateList({ commit }) {
    const response = await this.$api.get('deliveryDateList')
    const data = (response && response.details && response.details.data) || []
    const list = []
    for (const key in data) {
      list.push({
        text: data[key],
        value: key,
      })
    }
    commit('setDateList', list)
  },

  async loadTimeList({ commit }, date) {
    const response = await this.$api.get('deliveryTimeList', {
      params: {
        delivery_date: date,
      },
    })
    const data = (response && response.details && response.details.data) || []
    const list = []
    for (const key in data) {
      list.push(data[key])
    }
    commit('setTimeList', list)
  },

  async loadPaymentList({ commit, getters }) {
    const response = await this.$api.get('loadPaymentList', {
      params: { transaction_type: getters.deliveryType },
    })
    const data = (response && response.details && response.details.data) || []
    const list = []
    for (const key in data) {
      const {
        payment_name: text,
        payment_code: value,
        order_change: change,
      } = data[key]
      list.push({ text, value, change })
    }
    commit('setPaymentList', list)
  },

  async createOrder(store, params) {
    return await this.$api.get('payNow', { params })
  },

  async setDeliveryAddress({ commit }, params) {
    const response = await this.$api.get('setDeliveryAddress', { params })
    if (response.code != 1) {
      const text = response.msg || 'Ошибка при сохранении адреса'
      commit('snackbar/update', { active: true, text }, { root: true })
      return false
    }
    return true
  },

  async loadPickupPoints({ commit }) {
    const response = await this.$api.get('PointDelivery')
    const data = (response && response.details && response.details.data) || []
    const list = []
    for (const key in data) {
      list.push({
        text: data[key].place_name,
        value: data[key].id,
        default: data[key].default_point,
        is_dine_in: data[key].is_dine_in,
      })
    }
    commit('setPickupPoints', list)
  },

  async applyPromo(store, params) {
    return await this.$api.get('applyVoucher', { params })
  },

  async removePromo() {
    return await this.$api.get('removeVoucher')
  },

  async getStreetIiko(store, query) {
    const response = await this.$api.get('getStreetiiko', {
      params: { query },
    })

    const details = response?.details
    const streets = []

    if (response?.code == 1 && details?.length) {
      details.forEach((item) => {
        if (item.name == 'Бесплатная версия kladr-api.ru' || !item.name) return
        const name = item.typeShort
          ? `${item.typeShort} ${item.name}`
          : item.name
        streets.push(name)
      })
    }

    return streets
  },

  async geoAdressDelivery(store, params) {
    return await this.$api.get('geoAdressDelivery', { params })
  },

  async applyBonuses({ getters }, value) {
    return await this.$api.get('applyRedeemPoints', {
      params: { points: value, transaction_type: getters.deliveryType },
    })
  },

  async removeBonuses({ getters }) {
    return await this.$api.get('removePoints', {
      params: { transaction_type: getters.deliveryType },
    })
  },

  async loadOrderTypes({ commit, getters }) {
    const response = await this.$api.get('servicesList')
    commit('setOrderTypes', response.details.data)

    if (getters.orderTypes.some((item) => item.value === 'delivery')) {
      commit('setOrderType', 'delivery')
    } else if (getters.orderTypes.some((item) => item.value === 'pickup')) {
      commit('setOrderType', 'pickup')
    } else {
      commit('setOrderType', 'dinein')
    }
  },

  async selectComplete({ state, getters, commit, dispatch }) {
    if (state.orderType === 'pickup') {
      if (state.fields.pickupPoint) {
        commit('address/SET_ACTIVE', false, { root: true })
      } else {
        const text = 'Выберите пункт самовывоза'
        commit('snackbar/update', { text, active: true }, { root: true })
      }
    }

    if (state.orderType === 'dinein') {
      if (state.dineinPoint) {
        commit('setPickupPoint', state.dineinPoint)
        commit('address/SET_ACTIVE', false, { root: true })
      } else {
        const text = 'Выберите заведение'
        commit('snackbar/update', { text, active: true }, { root: true })
      }
    }

    if (state.orderType === 'delivery') {
      const { street, house, addressLine } = state.fields

      if (!street || !house) {
        const text = 'Укажите дом и улицу'
        commit('snackbar/update', { text, active: true }, { root: true })
        return
      }

      if (getters.useDeliveryZones) {
        const response = await dispatch(
          'checkout/geoAdressDelivery',
          { query: addressLine, preparatory_check: true },
          { root: true }
        )
        if (response.msg === 'error') {
          state.geoError = true
          const text =
            response.details.text || 'К сожалению, сюда не доставляем'
          commit('snackbar/update', { text, active: true }, { root: true })
          return
        } else {
          state.geoError = false
        }
      }
      commit('address/SET_ACTIVE', false, { root: true })
    }

    await dispatch('loadCart', state.orderType)
    commit('catalog/clearProducts', null, { root: true })
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
