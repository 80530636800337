import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('checkout', ['fields']),
    ...mapGetters('app', ['phone', 'phone2', 'additionalFields']),

    mapAttributes() {
      return {
        coords: [61.698657, 99.505405],
        bounds: null,
        zoom: 3,
        controls: ['zoomControl'],
        scrollZoom: false,
        options: { suppressMapOpenBlock: true },
      }
    },

    city() {
      return this.$store.getters['app/city']
    },

    cities() {
      return [this.$store.getters['app/city']]
    },

    initialAddress() {
      return this.fields.addressLine
    },

    kmlUrl() {
      return (
        this.$store.state.app.settings.map_with_delivery_zones +
        `?time=${new Date().getTime()}`
      )
    },

    privateHouse: {
      get() {
        return this.fields.apart === 'Частный дом'
      },
      set(value) {
        if (value) {
          this.updateField('apart', 'Частный дом')
        } else {
          this.updateField('apart', '')
        }
      },
    },

    addressActive() {
      return this.$store.state.address.active
    },

    isLoading() {
      return this.mapLoading
    },
  },

  watch: {
    isLoading(value) {
      if (value) {
        this.$emit('setLoading', true)
      } else {
        this.$emit('setLoading', false)
      }
    },
  },

  methods: {
    ...mapMutations('checkout', ['setField']),

    async generateZones() {
      try {
        this.mapLoading = true
        const res = await window.ymaps.geoXml.load(this.kmlUrl)
        res.geoObjects.each((item) => {
          if (item.properties.get('metaDataProperty')) {
            item.options.set(
              'fillColor',
              item.properties.get('metaDataProperty').fillColor
            )
            item.options.set(
              'fillOpacity',
              item.properties.get('metaDataProperty').fillOpacity
            )
            item.options.set(
              'strokeColor',
              item.properties.get('metaDataProperty').strokeColor
            )
            item.options.set(
              'strokeOpacity',
              item.properties.get('metaDataProperty').strokeOpacity
            )
            item.options.set(
              'strokeWidth',
              item.properties.get('metaDataProperty').strokeWidth
            )
          }

          item.balloon?.destroy()
          // Проверка на активность зоны
          if (!+item.properties.get('metaDataProperty')?.disabled) {
            item.events.add('click', this.onMapClick)
            item.properties.set(
              'hintContent',
              item.properties.get('metaDataProperty').outerName ||
                item.properties.get('metaDataProperty').name
            )
          } else {
            item.events.add('click', () => {
              this.$store.commit('snackbar/update', {
                active: true,
                text: 'Зона доставки временно недоступна',
              })
            })
            item.properties.set(
              'hintContent',
              (item.properties.get('metaDataProperty').outerName ||
                item.properties.get('metaDataProperty').name) +
                ' (временно недоступно)'
            )
            this.disabledZones.push(item)
          }
        })
        this.map.geoObjects.add(res.geoObjects)
        this.cityBounds = res.geoObjects.getBounds()
        if (!this.initialAddress) {
          this.map.setBounds(this.cityBounds)
        } else {
          this.geoCode(this.initialAddress)
        }
      } catch (error) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: 'Не удалось загрузить зоны доставки',
        })
      } finally {
        this.mapLoading = false
      }
    },

    onMapClick(event) {
      const coords = event.get('coords')
      this.$set(this.marker, 'coords', coords)
      this.geoCode(coords)
    },

    isPointInDisabled(point) {
      return this.disabledZones.some((item) => {
        if (item.geometry.contains(point.geometry.getCoordinates())) {
          return true
        }
      })
    },

    shortAddress() {
      if (!this.fields.addressLine) {
        return ''
      } else if (this.fields.house) {
        return [this.fields.street, this.fields.house].join(', ')
      } else {
        const arr = this.fields.addressLine.split(', ')
        return [arr[arr.length - 2], arr[arr.length - 1]].join(', ')
      }
    },

    isAdditionalFieldEnabled(field) {
      const settings = this.$store.state.app.settings.fields_addons
      return Boolean(settings?.[field] && Number(settings[field]) === 2)
    },

    updateField(key, value) {
      this.setField({ name: key, value: value })
    },

    onClear() {
      this.marker = {
        markerId: '1',
        coords: null,
        hintContent: '',
      }
      this.cityBounds && this.map.setBounds(this.cityBounds)
      this.address = ''
    },
  },

  async mounted() {
    document.querySelector('html').classList.toggle('overflow-y-hidden')
  },
}
