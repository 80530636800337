<template>
  <div class="socials">
    <a v-if="vk" :href="vk" class="social-link external-link" target="_blank">
      <!-- <VkIcon /> -->
      <img src="@/assets/images/social/vk.svg" alt="vk" />
    </a>

    <a
      v-if="instagram"
      :href="instagram"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <InstagramIcon /> -->
      <img src="@/assets/images/social/instagram.svg" alt="instagram" />
    </a>
    
    <a
      v-if="facebook"
      :href="facebook"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <FacebookIcon /> -->
      <img src="@/assets/images/social/facebook.svg" alt="facebook" />
    </a>

    <a
      v-if="google"
      :href="google"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <GoogleIcon /> -->
      <img src="@/assets/images/social/google.svg" alt="google" />
    </a>

    <a
      v-if="tripAdvisor"
      :href="tripAdvisor"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <TripAdvisorIcon /> -->
      <img src="@/assets/images/social/tripadvisor.svg" alt="tripadvisor" />
    </a>

    <a
      v-if="twitter"
      :href="twitter"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <TwitterIcon /> -->
      <img src="@/assets/images/social/twitter.svg" alt="twitter" />
    </a>

    <a
      v-if="youtube"
      :href="youtube"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <YoutubeIcon /> -->
      <img src="@/assets/images/social/youtube.svg" alt="youtube" />
    </a>

    <a
      v-if="yarus"
      :href="yarus"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <YarusIcon /> -->
      <img src="@/assets/images/social/yarus.svg" alt="yarus" />
    </a>

    <a
      v-if="zen"
      :href="zen"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <ZenIcon /> -->
      <img src="@/assets/images/social/zen.svg" alt="zen" />
    </a>

    <a
      v-if="tiktok"
      :href="tiktok"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <TikTokIcon /> -->
      <img src="@/assets/images/social/tiktok.svg" alt="tiktok" />
    </a>

    <a
      v-if="telegram"
      :href="telegram"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <TelegramIcon /> -->
      <img src="@/assets/images/social/telegram.svg" alt="telegram" />
    </a>

    <a
      v-if="whatsapp"
      :href="'https://wa.me/' + whatsapp"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <WhatsappIcon /> -->
      <img src="@/assets/images/social/whatsapp.svg" alt="whatsapp" />
    </a>


    <a
      v-if="ok"
      :href="ok"
      class="social-link external-link"
      target="_blank"
    >
      <!-- <OkIcon /> -->
      <img src="@/assets/images/social/odnoklassniki.svg" alt="ok" />
    </a>     
  </div>
</template>
<script>
// import VkIcon from "@/assets/images/social/vk.svg?inline";
// import InstagramIcon from "@/assets/images/social/instagram.svg?inline";
// import FacebookIcon from "@/assets/images/social/facebook.svg?inline";
// import GoogleIcon from "@/assets/images/social/google.svg?inline?inline";
// import TripAdvisorIcon from "@/assets/images/social/tripadvisor.svg?inline";
// import TwitterIcon from "@/assets/images/social/twitter.svg?inline";
// import YoutubeIcon from "@/assets/images/social/youtube.svg?inline";

export default {
  computed: {
    social() {
      return this.$store.getters["app/social"];
    },
    vk() {
      return this.social?.vk;
    },
    instagram() {
      return this.social?.insta;
    },
    facebook() {
      return this.social?.facebook;
    },
    google() {
      return this.social?.google;
    },
    tripAdvisor() {
      return this.social?.tripadvisor;
    },
    twitter() {
      return this.social?.twitter;
    },
    youtube() {
      return this.social?.youtube;
    },
    yarus() {
      return this.social?.yarus
    },
    zen() {
      return this.social?.zen
    },
    tiktok() {
      return this.social?.tiktok
    },
    telegram() {
      return this.social?.telegram
    },
    whatsapp() {
      return this.social?.whatsapp
    },
    ok() {
      return this.social?.ok
    },       
  },
};
</script>

<style scoped>
.socials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
}
.social-link {
  color: #000;
  color: rgba(0, 0, 0);
  background-color: #f0f0f0;
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  margin: 0 5px 5px 0;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 0.2s;
}
</style>